<template>
  <div class="home">
    <img src="../assets/imgs/home.jpg" alt="" class="background" />
    <img
      src="../assets/imgs/buttonplay.png"
      alt="点击前往查看"
      class="button"
      @click="$router.push('/folkCulture')"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 图片链接
      imgList: ["https://xm-h5demo.gztengyue.com.cn/img/bg.b321131d.png"],
    };
  },
  mounted() {
    // this.load();
  },
  methods: {
    // 预加载图片进度的方法
    load(imgSrc, callback) {
      var imgs = [];
      var c = 0;
      for (var i = 0; i < imgSrc.length; i++) {
        imgs[i] = new Image();
        imgs[i].src = imgSrc[i];
        imgs[i].onload = function () {
          c++;
          if (callback) {
            callback(c, imgSrc);
          }
        };
      }
      return imgs;
    },
    imgStatus(n, imgSrc) {
      //加载进度百分比 (加载数量 / 图片数量 * 100)
      // this.loadImgNum = parseInt(
      //   parseFloat(n / imgSrc.length).toFixed(2) * 100
      // );
      //做加载动画处理
      // console.log(loadImgNum);
      //如果加载完成执行
      // if (n == imgSrc.length) {
      //   console.log("ok");
      //   this.loadImgShow = false;
      //   this.drawShow = true;
      // }
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  position: relative;
  width: 100%;

  .background {
    width: 100%;
  }

  .button {
    width: 231px;
    height: 90px;
    // width: 60vw;
    // height: 13vh;
    position: absolute;
    top: 570px;
    transform: translate(-50%);
    left: 50%;
  }
}
</style>
