<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    document.title = "广府庙会民俗文化巡游";
  },
};
</script>

<style lang="less">
// html {
//   font-size: 50px;
// }
#app {
  width: 100%;
  overflow-x: hidden;
}
</style>
